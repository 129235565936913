<template>
  <div class="reserve-submit">
    <h3 class="title" v-if="!loadStatus && !error.length">
      {{ loading }}
    </h3>
    <h3 class="title error-text" v-if="error.length">
      {{ error }}
    </h3>
    <h3 class="title success-text" v-if="resultMsg.length">
      {{ resultMsg }}
    </h3>

    <div class="receipt" v-if="showReceipt">
      <h1>Ricevuta</h1>

      <span>Il pacco è stato consegnato alle {{ deliveredAt }}.</span>
      <span>Nel Locker: #{{ localStorage.lockerId }}</span>
    </div>

    <span class="countdown">Remaining Time: {{ remainingTime }} seconds</span>
  </div>
</template>

<style lang="scss" scoped>
.receipt {
  background-color: #eee;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 20px;
  }

  span {
    font-size: 20px;
    line-height: 22px;
  }
}

h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;

  &.error-text {
    background-color: rgba(red, 0.6);
  }

  &.success-text {
    background-color: rgba(green, 0.6);
  }
}
</style>

<script>
import apiService from "@/api/service.js";
import lockerConfig from "@/lockerConfig";

export default {
  name: "Leave/Submit",
  data() {
    return {
      loading: "Hold tight, loading...",
      loadStatus: false,
      error: "",
      resultMsg: "",
      showReceipt: false,
      lockerConfig: lockerConfig,
      deliveredAt: "",
      remainingTime: 300,
    };
  },
  mounted() {
    this.reserve();

    setTimeout(() => {
      if (!this.loadStatus) {
        this.loading = "It is taking longer than expected...";

        setTimeout(() => {
          if (!this.loadStatus) {
            this.loading = "Sorry for the inconvenience, still loading...";
          }
        }, 4000);
      }
    }, 2000);

    const remainingHandler = () => {
      this.remainingTime--;

      if (this.remainingTime == 0) {
        this.$router.push({
          name: "Home",
        });
      } else {
        setTimeout(remainingHandler, 1000);
      }
    };

    setTimeout(remainingHandler, 1000);
  },
  methods: {
    pollDrawer: async function (drawerId, maxTry = 120, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == false) {
          return true;
        }

        return await this.pollDrawer(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawer(drawerId, maxTry, errorCount + 1);
      }
    },
    pollDrawerOpen: async function (drawerId, maxTry = 30, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == true) {
          return true;
        }

        return await this.pollDrawerOpen(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawerOpen(drawerId, maxTry, errorCount + 1);
      }
    },
    reserve: async function () {
      const sizeCodes = ["s", "m", "l", "xl"];

      let barcode = this.$route.query.barcode;
      let receiptEmail = this.$route.query.receiptEmail;
      let sizeCode = this.$route.query.sizeCode;

      if (!sizeCodes.includes(sizeCode)) {
        this.error = "Size code parameter is wrong, try again.";
        return;
      }

      if (barcode.length <= 0 || sizeCode.length <= 0) {
        this.error =
          "There are one or more empty fields in the form, try again.";
        return;
      }

      try {
        const result = await apiService.depositPackageStep1(
          barcode,
          sizeCode,
          receiptEmail
        );

        if (!result) {
          throw "No drawers available";
        }

        console.log(result);

        this.loadStatus = true;
        this.resultMsg = "Done, please wait until the drawer is opened...";

        const drawerId = result;
        const drawerData = await apiService.getDrawer(drawerId);

        if (drawerData.blackout_state == 1) {
          this.resultMsg = "";
          this.error = `Drawer ${drawerId} is currently in blackout state, please try getting this package later and contact your system administrator.`;
          return;
        }

        try {
          const open = await apiService.openDrawer(drawerId);
        } catch (err) {
          this.resultMsg =
            "System error while opening the drawer, please contact system administrator.";
          return;
        }

        this.resultMsg = "Opening the drawer, please wait...";

        // Poll the drawer until it is opened
        const pollOpen = await this.pollDrawerOpen(drawerId);
        if (!pollOpen) {
          this.resultMsg =
            "The drawer is not opened, please try again later or contact system administrator.";
          return;
        }

        this.resultMsg =
          "Opened the drawer, please leave your package and close it.";

        // await apiService.depositPackageStep2(barcode, drawerId, receiptEmail);
        for (let i = 0; i < 5; i++) {
          if (
            (await apiService.depositPackageStep2(
              barcode,
              drawerId,
              receiptEmail
            )) != false
          ) {
            break;
          }

          await new Promise((resolve) => setTimeout(resolve, 500));
        }

        await new Promise((resolve) => setTimeout(resolve, 5000));
        const poll = await this.pollDrawer(drawerId);
        if (!poll) {
          this.resultMsg =
            "Your package has been deposited. Please close the drawer. Thank you!";
        } else {
          this.resultMsg = "Thanks you for choosing us, have a nice day!";
        }
      } catch (error) {
        console.log(error);
        this.error = "There is a system error occured. Please try again later.";
        return;
      }

      this.deliveredAt = new Date().toLocaleString();
      this.showReceipt = true;

      setTimeout(() => {
        this.$router.push({
          name: "Home",
        });
      }, 50000);
      return;
    },
  },
};
</script>
